import { Component, OnInit } from '@angular/core';




export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;

}

export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon: 'nc-bank', class: '' },
  { path: '/admin', title: 'Admins', icon: 'nc-circle-10', class: '' },
  { path: '/users', title: 'Users', icon: 'nc-single-02', class: '' },
  { path: '/companyuser', title: 'Company Users', icon: 'nc-single-02', class: '' },
  { path: '/company', title: 'Architecture Comp.', icon: 'nc-tv-2', class: '' },
  { path: '/construccompany', title: 'Contruction Comp.', icon: 'nc-tv-2', class: '' },
  { path: '/buildingcompany', title: 'Building Comp.', icon: 'nc-tv-2', class: '' },
  { path: '/rank', title: 'Architecture Rank', icon: 'nc-tv-2', class: '' },
  { path: '/construcrank', title: 'Construction Rank', icon: 'nc-tv-2', class: '' },
  { path: '/buildingrank', title: 'Building Rank', icon: 'nc-tv-2', class: '' },
  { path: '/bussinesstype', title: 'Business Type', icon: 'nc-tv-2', class: '' },
  { path: '/request', title: 'Requests', icon: 'nc-single-copy-04', class: '' },
  { path: '/review', title: 'Reviews', icon: 'nc-email-85', class: '' },
  { path: '/question', title: 'Questions', icon: 'nc-email-85', class: '' },
  { path: '/offer', title: 'Offers', icon: 'nc-send', class: '' },
  { path: '/city', title: 'Cities', icon: 'nc-world-2', class: '' },
  { path: '/image', title: 'Images', icon: 'nc-world-2', class: '' },
  { path: '/type', title: 'Request Types', icon: 'nc-world-2', class: '' },
  { path: '/settings', title: 'Settings', icon: 'nc-settings', class: '' },

];

@Component({
  moduleId: module.id,
  selector: 'sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {




  constructor(


  ) {
  }

  public menuItems: any[];
  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);

    console.log(this.menuItems);
  }



}
